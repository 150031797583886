import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../styles/Profile.css";

const Profile = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Handle Google login with code
    const retrieveTokenFromCode = async (code) => {
      try {
        const response = await axios.post(
          "https://dev-v4cnr8426mdu0jxl.us.auth0.com/oauth/token",
          {
            grant_type: "authorization_code",
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
            client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
            code,
            redirect_uri: "http://localhost:3000/profile",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const { access_token } = response.data;
        localStorage.setItem("authToken", access_token);
        fetchUserProfile(access_token);
      } catch (error) {
        console.error("Token exchange failed:", error);
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    // Handle direct token in URL fragment
    const retrieveTokenFromUrlFragment = () => {
      const hash = window.location.hash;
      const params = new URLSearchParams(hash.replace("#", "?"));
      const accessToken = params.get("access_token");

      if (accessToken) {
        localStorage.setItem("authToken", accessToken);
        fetchUserProfile(accessToken);
      } else {
        setLoading(false);
      }
    };

    // Fetch user profile
    const fetchUserProfile = async (token) => {
      try {
        const response = await axios.get(
          "https://dev-v4cnr8426mdu0jxl.us.auth0.com/userinfo",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setUserProfile(response.data);
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
        localStorage.removeItem("authToken");
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    // Determine authentication flow
    const handleAuthFlow = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const token = localStorage.getItem("authToken");

      if (code) {
        retrieveTokenFromCode(code); // Handle Google login with authorization code
      } else if (token) {
        fetchUserProfile(token); // Handle token-based login
      } else {
        retrieveTokenFromUrlFragment(); // Handle token in URL fragment
      }
    };

    handleAuthFlow();
  }, [navigate]);

  if (loading) return <p>Loading...</p>;

return (
  <div className="profile-page">
    {userProfile ? (
      <div className="profile-card">
        <img
          src={userProfile.picture}
          alt="User profile"
          className="profile-picture"
          style={{ width: "100px", height: "100px", borderRadius: "50%" }}
        />
        <h1 className="welcome-message">
          Welcome, {userProfile.name || "User"}!
        </h1>
        <p className="user-info">
          Email: {userProfile.email || "Email not available"}
        </p>
        <button
          className="logout-button"
          onClick={() => {
            localStorage.removeItem("authToken");
            navigate("/");
          }}
        >
          Logout
        </button>
      </div>
    ) : (
      <p>You are not logged in.</p>
    )}
  </div>
);
};

export default Profile;
