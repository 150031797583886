// // src/Signup.js
// import React, { useState, useEffect } from "react";
// import axios from "axios"; // Import axios for API calls
// import "../../styles/EmailSignup.css";
// import { useNavigate } from "react-router-dom";

// const EmailSignup = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [error, setError] = useState(null);
//   const [success, setSuccess] = useState(false);
//   const [passwordError, setPasswordError] = useState("");
//   const [confirmPasswordError, setConfirmPasswordError] = useState("");
//   const navigate = useNavigate();
//   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//   // -------------------------------------
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   useEffect(() => {
//     // Check if a refresh token exists and refresh the access token
//     if (localStorage.getItem("refresh_token")) {
//       refreshAccessToken();
//     }
//   }, []);
//   // -------------------------------------

//   const validatePassword = (value) => {
//     const minLength = 8;
//     const hasNumber = /\d/;
//     const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

//     if (value.length < minLength) {
//       setPasswordError("Password must be at least 8 characters long.");
//     } else if (!hasNumber.test(value)) {
//       setPasswordError("Password must include at least one number.");
//     } else if (!hasSpecialChar.test(value)) {
//       setPasswordError("Password must include at least one special character.");
//     } else {
//       setPasswordError("");
//     }
//   };

//   // Function to check if passwords match
//   const validateConfirmPassword = (value) => {
//     if (value !== password) {
//       setConfirmPasswordError("Passwords do not match.");
//     } else {
//       setConfirmPasswordError("");
//     }
//   };

//   const handleSignup = async () => {
//     if (passwordError || confirmPasswordError) {
//       setError("Please resolve all errors before signing up.");
//       return;
//     }
//     if (!emailPattern.test(email)) {
//       setError("Please enter a valid email address.");
//       return;
//     }
//     try {
//       console.log("Attempting signup...");
//       const response = await axios.post(
//         "https://dev-v4cnr8426mdu0jxl.us.auth0.com/api/v2/users", // Your Auth0 domain
//         {
//           email,
//           password,
//           connection: "Username-Password-Authentication", // Auth0 email/password connection
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${process.env.REACT_APP_AUTH0_API_TOKEN}`, // Use Auth0 API token
//           },
//         }
//       );

//       console.log("Signup successful", response.data);
//       localStorage.setItem("refresh_token", response.data.refresh_token);
//       setSuccess(true);
//       setError(null);
//       // Redirect to login after successful signup
//       navigate("/");
//     } catch (error) {
//       if (error.response && error.response.status === 409) {
//         console.error("Signup failed: Email already in use.");
//         setError("This email is already registered. Please try logging in.");
//       } else {
//         console.error("Signup failed:", error.response?.data || error.message);
//         setError("Signup failed. Please try again.");
//       }
//     }
//   };

//   // Function to refresh the access token -- New Code Addded

//   // -----------------------------------------------------------------------

//   const refreshAccessToken = async () => {
//     try {
//       const response = await axios.post(
//         "https://dev-v4cnr8426mdu0jxl.us.auth0.com/oauth/token",
//         {
//           grant_type: "refresh_token",
//           client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
//           refresh_token: localStorage.getItem("refresh_token"),
//         }
//       );

//       // Update the access token in the headers for further API calls
//       const newAccessToken = response.data.access_token;
//       axios.defaults.headers.common[
//         "Authorization"
//       ] = `Bearer ${newAccessToken}`;

//       console.log("Access token refreshed successfully");
//     } catch (error) {
//       console.error(
//         "Failed to refresh token:",
//         error.response?.data || error.message
//       );
//       setError("Session expired. Please log in again.");
//       localStorage.removeItem("refresh_token");
//       navigate("/");
//     }
//   };
//   //--------------------------------------------------------------------------------

//   return (
//     <div className="email-signup-page">
//       <div className="left-section">
//         <img
//           src={`${process.env.PUBLIC_URL}/Holiday_image.png`}
//           alt="Holiday"
//           className="holiday-image"
//         />
//       </div>
//       <div className="form-container">
//         <h1 className="logo">Holiday Channel</h1>
//         {/* <div className="image-section"></div> */}
//         <h2>Sign up with Email</h2>
//         {error && <p style={{ color: "red" }}>{error}</p>}
//         {success && (
//           <p style={{ color: "green" }}>
//             Registration successful! Redirecting to login...
//           </p>
//         )}
//         <form className="signup-form">
//           <label>Email Address</label>
//           <input
//             type="email"
//             placeholder="yourname@example.com"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//           <label>Password</label>
//           <input
//             type="password"
//             placeholder="Enter your password"
//             value={password}
//             onChange={(e) => {
//               setPassword(e.target.value);
//               validatePassword(e.target.value);
//             }}
//             required
//           />
//           {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}

//           <label>Confirm Password</label>
//           <input
//             type="password"
//             placeholder="Re-enter your password"
//             value={confirmPassword}
//             onChange={(e) => {
//               setConfirmPassword(e.target.value);
//               validateConfirmPassword(e.target.value);
//             }}
//             required
//           />
//           {confirmPasswordError && (
//             <p style={{ color: "red" }}>{confirmPasswordError}</p>
//           )}
//           <button type="button" onClick={handleSignup}>
//             SIGN UP
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default EmailSignup;

import React, { useState } from "react";
import axios from "axios"; // Import axios for API calls
import "../../styles/EmailSignup.css";
import { useNavigate } from "react-router-dom";

const EmailSignup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const navigate = useNavigate();
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validatePassword = (value) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    if (value.length < minLength) {
      setPasswordError("Password must be at least 8 characters long.");
    } else if (!hasNumber.test(value)) {
      setPasswordError("Password must include at least one number.");
    } else if (!hasSpecialChar.test(value)) {
      setPasswordError("Password must include at least one special character.");
    } else {
      setPasswordError("");
    }
  };

  const validateConfirmPassword = (value) => {
    if (value !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleSignup = async () => {
    if (passwordError || confirmPasswordError) {
      setError("Please resolve all errors before signing up.");
      return;
    }
    if (!emailPattern.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    try {
      const response = await axios.post(
        "https://dev-v4cnr8426mdu0jxl.us.auth0.com/api/v2/users",
        {
          email,
          password,
          connection: "Username-Password-Authentication",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_AUTH0_API_TOKEN}`,
          },
        }
      );

      localStorage.setItem("refresh_token", response.data.refresh_token);
      setSuccess(true);
      setError(null);
      navigate("/");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("This email is already registered. Please try logging in.");
      } else {
        setError("Signup failed. Please try again.");
      }
    }
  };
  // eslint-disable-next-line no-unused-vars
  const refreshAccessToken = async () => {
    try {
      const response = await axios.post(
        "https://dev-v4cnr8426mdu0jxl.us.auth0.com/oauth/token",
        {
          grant_type: "refresh_token",
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          refresh_token: localStorage.getItem("refresh_token"),
        }
      );

      const newAccessToken = response.data.access_token;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${newAccessToken}`;
    } catch (error) {
      setError("Session expired. Please log in again.");
      localStorage.removeItem("refresh_token");
      navigate("/");
    }
  };

  return (
    <div className="email-signup-page">
      <div className="left-section">
        <img
          src={`${process.env.PUBLIC_URL}/Holiday_image.png`}
          alt="Holiday"
          className="holiday-image"
        />
      </div>
      <div className="form-container">
        <h1 className="logo">Holiday Channel</h1>
        <h2>Sign up with Email</h2>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && (
          <p style={{ color: "green" }}>
            Registration successful! Redirecting to login...
          </p>
        )}
        <form className="signup-form">
          <label>Email Address</label>
          <input
            type="email"
            placeholder="yourname@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label>Password</label>
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              validatePassword(e.target.value);
            }}
            required
          />
          {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}

          <label>Confirm Password</label>
          <input
            type="password"
            placeholder="Re-enter your password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              validateConfirmPassword(e.target.value);
            }}
            required
          />
          {confirmPasswordError && (
            <p style={{ color: "red" }}>{confirmPasswordError}</p>
          )}
          <button type="button" onClick={handleSignup}>
            SIGN UP
          </button>
        </form>
      </div>
    </div>
  );
};

export default EmailSignup;
