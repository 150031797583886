import React from "react";
import "../../styles/Login.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const handleEmailLogin = () => {
    navigate("/email-login");
  };

  const nonce = Math.random().toString(36).substring(2, 15);
  const state = Math.random().toString(36).substring(2, 15);
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = "http://localhost:3000/profile";

  const facebookLoginUrl =
    `https://dev-v4cnr8426mdu0jxl.us.auth0.com/authorize?` +
    `client_id=${clientId}` +
    `&redirect_uri=${encodeURIComponent(redirectUri)}` +
    `&connection=facebook` +
    `&scope=openid%20profile%20email` +
    `&response_type=token%20id_token` +
    `&nonce=${nonce}` +
    `&state=${state}`;
  console.log(facebookLoginUrl);

  const handleFacebookLogin = () => {
      window.location.href = facebookLoginUrl;
    };

  const handleGoogleLogin = () => {
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const redirectUri = "http://localhost:3000/profile";

    const googleAuthUrl = `https://${domain}/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid profile email&connection=google-oauth2`;

    window.location.href = googleAuthUrl;
  };

  const redirectToSignup = () => {
    navigate("/signup");
  };

  return (
    <div className="login-page">
      <div className="left-section">
        <img
          src={`${process.env.PUBLIC_URL}/Holiday_image.png`}
          alt="Holiday"
          className="holiday-image"
        />
      </div>
      <div className="form-container">
        {/* <div className="login-section"> */}
          <h1 className="logo">Holiday Channel</h1>
          <h2>Welcome to Holiday Channel</h2>
          <p>Log in to enjoy exclusive content and stay connected with us!</p>

          <div className="login-buttons">
            <button className="login-btn email" onClick={handleEmailLogin}>
              <i className="fas fa-envelope"></i> Log in with Email
            </button>
            <button
              className="login-btn facebook"
              onClick={handleFacebookLogin}
            >
              <i className="fab fa-facebook-f"></i> Log in with Facebook
            </button>
            <button className="login-btn google" onClick={handleGoogleLogin}>
              <i className="fab fa-google"></i> Log in with Google
            </button>
          </div>

          <p className="signup-prompt">
            Don't have an account?{" "}
            <span className="link" onClick={redirectToSignup}>
              Join now
            </span>
          </p>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Login;
