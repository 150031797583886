import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";
import EmailSignup from "./Auth/EmailSignup";
import EmailLogin from "./Auth/EmailLogin";
import Profile from "./Auth/Profile";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/email-signup" element={<EmailSignup />} />
      <Route path="/email-login" element={<EmailLogin />} />
      <Route path="/profile" element={<Profile />} />
    </Routes>
  );
}

export default App;
