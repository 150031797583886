import React, { useState } from "react";
import axios from "axios";
import "../../styles/EmailLogin.css";
import { useNavigate } from "react-router-dom";

const EmailLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      console.log("Attempting login...");
      const response = await axios.post(
        "https://dev-v4cnr8426mdu0jxl.us.auth0.com/oauth/token",
        {
          grant_type: "password",
          username: email,
          password: password,
          audience: "https://dev-v4cnr8426mdu0jxl.us.auth0.com/api/v2/",
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
          scope: "openid profile email",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Login successful", response.data);
      setSuccess(true);
      localStorage.setItem("authToken", response.data.access_token);
      navigate("/profile");
    } catch (error) {
      console.error("Login failed:", error.response?.data || error.message);
      setError("Login failed. Please check your credentials and try again.");
    }
  };

  return (
    <div className="email-login-page">
      <div className="left-section">
        <img src={`${process.env.PUBLIC_URL}/Holiday_image.png`} alt="Holiday" className="holiday-image" />
      </div>
      <div className="login-section">
        <h1 className="logo">Holiday Channel</h1>
        <h2>Log in with Email</h2>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && <p style={{ color: "green" }}>Login successful! Redirecting...</p>}
        <form className="login-form">
          <label>Email Address</label>
          <input
            type="email"
            placeholder="yourname@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label>Password</label>
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="button" onClick={handleLogin}>
            Log in
          </button>
          <p>
            Don't have an account?{" "}
            <span className="link" onClick={() => navigate("/signup")}>
              Join now
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default EmailLogin;
