import React from "react";
import "../../styles/Signup.css";
import { useNavigate } from "react-router-dom";



const SignUp = () => {

  const Navigate = useNavigate();

  const redirectToLogin = () => { 
    Navigate("/");
  };

  const handleEmailSignUp = () => {
    Navigate("/email-signup");
  };
  
  const nonce = Math.random().toString(36).substring(2, 15);
  const state = Math.random().toString(36).substring(2, 15);
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = "http://localhost:3000/profile";

  const facebookLoginUrl =
    `https://dev-v4cnr8426mdu0jxl.us.auth0.com/authorize?` +
    `client_id=${clientId}` +
    `&redirect_uri=${encodeURIComponent(redirectUri)}` +
    `&connection=facebook` +
    `&scope=openid%20profile%20email` +
    `&response_type=token%20id_token` +
    `&nonce=${nonce}` +
    `&state=${state}`;
  console.log(facebookLoginUrl);

  const handleFacebookSignUp = () => {
    window.location.href = facebookLoginUrl;
  };

  const handleGoogleSignUp = () => {
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const redirectUri = "http://localhost:3000/profile";

    const googleAuthUrl = `https://${domain}/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid profile email&connection=google-oauth2`;

    window.location.href = googleAuthUrl;
    
  };

  return (
    <div className="sign-up-page">
      <div className="left-section">
        <img src={`${process.env.PUBLIC_URL}/Holiday_image.png`} alt="Holiday" className="holiday-image" />
      </div>
      <div className="form-container">
        <h1 className="logo">Holiday Channel</h1>
        <h2>Create an account</h2>
        <p>Sign up to rate and review your favorite Holiday Channel recipes.</p>
        <div className="signup-buttons">
          <button className="signup-btn email" onClick={handleEmailSignUp}>
            <i className="fas fa-envelope"></i> Sign up with Email
          </button>
          <button
            className="signup-btn facebook"
            onClick={handleFacebookSignUp}
          >
            <i className="fab fa-facebook-f"></i> Sign up with Facebook
          </button>
          <button className="signup-btn google" onClick={handleGoogleSignUp}>
            <i className="fab fa-google"></i> Sign up with Google
          </button>
        </div>

        <p className="login-prompt">
          Have an account?{" "}
          <span className="link" onClick={redirectToLogin}>
            Log in
          </span>
        </p>

        <p className="terms">
          By signing up, you agree to the <a href="/terms">Terms of Service</a>{" "}
          and <a href="/privacy">Privacy Policy</a>. If you live in the US, you
          will also opt in to Holiday Channel email communication.
        </p>
      </div>
    </div>
  );
};

export default SignUp;

